---
title: 'Localizing strings'
metaTitle: 'Admin-UI Docs | Best practices > Localizing strings'
metaDescription: 'How we localize any visible text in Admin-UI'
---

## What is string localization?

Strings localization or internationalization is the process of making every text visible by the user easily translatable.

### Why

-   Even though the Admin Console UI is currently only available in English, it might not always be the case in the future.
-   It makes it easier for the documentation team to review and make changes to the visible text of the Admin Console UI, because all the strings are in the same place.

Note that using formatting properly is just as important as using translation keys properly.
Especially when you display a User Interface in a user's language, using unexpected formatting (e.g. English) can get very confusing.
As a simple example, English is one of the few cultures that uses `MM/DD/YYYY` for dates, French uses `DD/MM/YYYY`.
Formatting numbers and date values is provided by the core/format package.

### How

Suppose you want to localize a string inside the `empty-states` package. You would have to add your string in one of the `.json` files inside the `src/strings` folder (e.g., `components/empty-states/src/strings/strings.json`).

```json
{
    "yourString": "This is the text that you need to show"
}
```

Then you can output that string in the code where you need it using the `Locales.format` function.

```tsx
import {Locales} from '../strings/Locales'; // located in src/strings/Locales.ts

const yourString = Locales.format('yourString');

// You can also use the function directly in a React component:
export const MyComponent: FunctionComponent = () => <div>{Locales.format('yourString')}</div>;
```

## Strings sub-folder nesting

When `.json` string files become too large, we can split and restructure them using sub-folders. We have an example of this in the `jsadmin-search-optimization` folder.

For example if you want to display a string from the `packages/jsadmin-search-optimization/src/strings/Triggers/triggers.json` file you would do it like so:

```ts
locales.format('Triggers.addTrigger'); // outputs "Add rule"
```

## Formatting to title case (deprecated)

_This option is deprecated, our documentation team prefers us to use the correct casing in the string_

Any localized string can be formatted to be in title case with the `titleCase` option.

```json
{
    "pizzaTaste": "pizza tastes good"
}
```

```ts
Locales.format('pizzaTaste', {titleCase: true});
// outputs "Pizza Tastes Good"
```

## Parameterized strings

Localized strings can also be parameterized. It helps to avoid declaring multiple instances of the same string with small differences.

```json
{
    "pizzaTaste": "pizza tastes %{taste}",
    "good": "good",
    "great": "great"
}
```

```ts
const good = Locales.format('good');
const great = Locales.format('great');

Locales.format('pizzaTaste', {taste: good});
// outputs "pizza tastes good"

Locales.format('pizzaTaste', {taste: great});
// outputs "pizza tastes great"
```

## Handling plurals

The plural version of the same string can be handled using the `smart_count` option.

First we have to create a string that has both the singular and the plural version separated by the `||||` symbol: `singular |||| plural`

```json
{
    "pizzaSlice": "pizza slice |||| pizza slices"
}
```

```ts
Locales.format('pizzaSlice', {smart_count: 0});
// 0 is plural in English, outputs "pizza slices"

Locales.format('pizzaSlice', {smart_count: 1});
// 1 is singular in English, outputs "pizza slice"

Locales.format('pizzaSlice', {smart_count: 2});
// 2 is plural in English, outputs "pizza slices"
```

## Combining formatting options

Combining multiple formatting string options can get pretty powerful.

```json
{
    "pizzaSlice": "a pizza slice tastes %{taste} |||| %{smart_count} pizza slices taste %{taste}",
    "good": "good",
    "better": "better"
}
```

```ts
const good = Locales.format('good');
const better = Locales.format('better');

Locales.format('pizzaSlice', {smart_count: 1, taste: good});
// outputs "a pizza slice tastes good"

Locales.format('pizzaSlice', {smart_count: 3, taste: better});
// outputs "3 pizza slices taste better"
```

## React advanced use-case

Sometimes you need to have basic HTML markup in your localized strings. One way of doing this is by using `dangerouslySetInnerHTML` but as the name implies it can cause security issues. **Every user input must be escaped.**

```json
{
    "stringWithHTML": "Hello <strong>%{firstName}</strong>!"
}
```

```tsx
export const MyComponent: FunctionComponent<{user: User}> = ({user}) => (
    <>
        <div
            id="GOOD"
            dangerouslySetInnerHTML={{
                __html: Locales.format('stringWithHTML', {firstName: _.escape(user.firstName)}),
            }}
        />
        <div
            id="BAD"
            dangerouslySetInnerHTML={{
                __html: Locales.format('stringWithHTML', {firstName: user.firstName}),
            }}
        />
    </>
);
```

Instead, you should rely on the `Translation` component from the `core/locales` package. It is based on [react-i18next's Trans component](https://github.com/i18next/react-i18next/blob/master/src/TransWithoutContext.js) and automatically handles escaping.

To add a child you simply have to use placeholder components (`<0>...</0>`, `<1>...</1>`, etc) in the string:

```json
{
    "stringWithHTML": "Hello <0>%{firstName}</0>!",
    "errorDescription": "Your test organization <0>%{organizationName}<0> has limited performances. Consider <1>creating a trial organization</1> if you need more flexible limits."
}
```

```tsx
import {Translation} from '@core/locales';
import {Locales} from '../strings/Locales';

export const MyComponent: FunctionComponent<{user: User}> = ({user}) => (
    <div>
        <Translation t={Locales} i18nKey="stringWithHTML" options={{firstName: user.firstName}}>
            <strong />
        </Translation>

        <Translation t={Locales} i18nKey="errorDescription" options={{organizationName: 'Potato'}}>
            <strong />
            <a target="_blank" href="https://www.coveo.com/en/free-trial" />
        </Translation>
    </div>
);
```

## Practical tips

There are some important things to keep in mind when building user interfaces that adapt to different languages and locales.
Text length may vary pretty much across languages, and English is most definitely not the "longest" language.
So allow some room in your UI implementation for text to be longer.

### Use templates to compose sentences

Word order is different across languages, so use _templates_ over _string concatenation_.
String concatenation is any form of combining parts of (localized) strings to create a whole sentence.
Note that even though it's named [template literals](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals),
using backtick templates string is effectively string concatenation too.

✅ Do

```json
{
    "progress": "Step %{currentStep} of %{totalSteps}."
}
```

```typescript
const output = Locales.format('progress', {
    currentStep: 1,
    totalSteps: 6,
});
```

❌ Don't

```json
{
    "step": "Step",
    "of": "of"
}
```

```typescript
// Bad:
//  1. Translators have no way of knowing "step" and "of" are used to build a sentence.
//  2. This will fail with languages that have different word order, or that need text after the last number.
const output = `${Locales.format('step')} 1 ${Locales.format('of')} 6.`;
```

Different languages/cultures may have different ways to display _x of y_, some may even reverse the word order.
By using a template over "string concatenation" (even if done with a JavaScript template literal),
translators can recognize the construct and translate it to something that makes sense in the target language.

### Let formatters localize values

This is somewhat related to "do not concatenate strings"; different languages may put "unit" indicators in different places.
The most obvious example is currency symbols, where some cultures put it in front of the amount, while others put it behind.

```typescript
// Take these neighboring countries, Germany and the Netherlands, for example:
new Intl.NumberFormat('de', {style: 'currency', currency: 'EUR'}).format(123.45);
// '123,45 €';

new Intl.NumberFormat('nl', {style: 'currency', currency: 'EUR'}).format(123.45);
// '€ 123,45';

// English is even different, by not using a space between:
new Intl.NumberFormat('en', {style: 'currency', currency: 'EUR'}).format(123.45);
// '€123.45';
```

So select the correct formatter and let it choose how to represent a value **including** any currency symbol/percentage sign/unit,
instead of encoding that representation in the translation string.

✅ Do

```json
{
    "costSummary": "Your costs this month were %{cost}, %{difference} compared to last month."
}
```

```typescript
import {NumberFormatter} from '@core/format';

const priceFormatter = NumberFormatters.currencyFormatter('USD');
const current = 123.45;
const previous = 99.99;
const relativeDifference = (current - previous) / previous;

const output = Locales.format('costSummary', {
    cost: priceFormatter.format(123.45),
    difference: NumberFormatter.percentFull.format(relativeDifference),
});
```

❌ Don't

```json
{
    "costSummary": "Your costs this month were $%{cost}, %{difference}% compared to last month."
}
```

```typescript
import {NumberFormatter} from '@core/format';

const current = 123.45;
const previous = 99.99;
const relativeDifference = (current - previous) / previous;

// Bad:
//  1. Hard-coded dollar sign, prefixed to the value (without space).
//  2. Hard-coded percent sign after the value.
const output = Locales.format('costSummary', {
    cost: NumberFormatter.decimalFull.format(123.45),
    difference: NumberFormatter.decimalFull.format(relativeDifference * 100),
});
```
